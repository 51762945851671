<script>
import { mapGetters } from 'vuex';

import TicketCardV2 from '../../components/TicketCard.vue';
import DisplayMixin from '@/mixins/DisplayMixin';
import TicketsManagerMixin from '~/features/tickets/mixins/TicketsManagerMixin';
import {
  loadMore,
  showLoadMore,
  ticketsForDate,
} from '~/features/tickets/utils/common';
import ColorUtilities from '~/utilities/ColorUtilities';

import TicketCard from '~/features/tickets/components/organisms/TicketCard';

const CONTAINER_SIZE_NORMAL = 240;
const CONTAINER_SIZE_EXTENDED = 440;

export default defineNuxtComponent({
  components: { TicketCard, TicketCardV2 },
  mixins: [DisplayMixin, TicketsManagerMixin],
  props: {
    ticketsDates: {
      type: Set,
      required: true,
    },
    layoutType: {
      type: String,
      required: true,
    },
    loadingTickets: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ColorUtilities,
      CONTAINER_SIZE_NORMAL,
      CONTAINER_SIZE_EXTENDED,
    };
  },
  computed: {
    ...mapGetters('FilteringStore', ['filteringMenuOpened']),
    ...mapGetters('TicketsModuleStore', [
      'selectedTickets',
      'processingAction',
      'fetchedTickets',
    ]),
    showClearSelection() {
      return this.selectedTickets.length > 0;
    },
  },
  methods: {
    loadMore,
    ticketsForDate,
    onClearSelection() {
      this.clearSelectedTickets();
    },
    openTicketDetailsModal(ticket) {
      this.$router.push({ path: this.$route.path, query: { id: ticket.id } });
    },
    loadMoreTickets() {
      this.$emit('loadMoreTickets');
    },
    showLoadMore,
  },
});
</script>

<template>
  <div class="flex flex-col">
    <div class="mt-4">
      <perfect-scrollbar
        :style="
          computedContainerCorrection(
            CONTAINER_SIZE_NORMAL,
            CONTAINER_SIZE_EXTENDED,
            filteringMenuOpened,
          )
        "
      >
        <div
          v-if="processingAction"
          class="flex w-full items-center justify-center"
        >
          {{ __('Loading...') }}
        </div>
        <div v-else class="flex flex-col">
          <div
            v-for="(ticketDate, index) in ticketsDates"
            :key="ticketDate.id"
            class="mt-4"
          >
            <div class="flex">
              <strong>
                {{ ticketDate }}
              </strong>
              <div
                v-show="index === 0 && showClearSelection"
                class="ml-auto flex cursor-pointer pr-4"
                @click="onClearSelection"
              >
                <trailblazer-icon
                  color="var(--tb-color-error)"
                  name="remove"
                />
                <span class="font-normal text-error">{{
                  __('Clear selection')
                }}</span>
              </div>
            </div>
            <div
              v-for="ticket in ticketsForDate(fetchedTickets.data, ticketDate)"
              :key="ticket.id"
              @click="openTicketDetailsModal(ticket)"
            >
              <TicketCardV2 :data="ticket" />
            </div>
          </div>

          <trailblazer-button
            v-if="showLoadMore(fetchedTickets)"
            variant="secondary"
            :disabled="loadingTickets"
            class="mt-2"
            @click="loadMoreTickets"
          >
            {{
              loadingTickets
                ? __('Loading...')
                : __(
                  sprintf(
                    'Load more (%d remaining)',
                    fetchedTickets.meta.total - fetchedTickets.data.length,
                  ),
                )
            }}
          </trailblazer-button>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style scoped>
.ps {
  max-height: calc(100vh - var(--containerCorrection));
}
</style>
